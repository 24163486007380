<template>
  <h1 class="text-2xl font-semibold text-gray-900">
    Vouchers, Waardebonnen &amp; Promoties
  </h1>

  <div class="card flex flex-col gap-2">
    <div class="flex flex-col md:flex-row justify-between gap-2">
      <UITabs :tabs="tabs" v-model="tab" />
    </div>

    <KeepAlive>
      <component :is="Views[tab]" />
    </KeepAlive>
  </div>
</template>

<script setup>
import { defineAsyncComponent, computed, ref } from 'vue'
import UITabs from '@/components/UI/Tabs.vue'

const tabs = ['Lijst', 'Historiek']
const tab = ref(0)
const Views = computed(() => tabs.map(tab => defineAsyncComponent(() => import(`./${tab}.vue`))))
</script>
